import Vue from 'vue'
import VueRouter from 'vue-router'

// 解决跳转相同路由报错问题
// 获取原型对象push函数
const originalPush = VueRouter.prototype.push
// 获取原型对象replace函数
const originalReplace = VueRouter.prototype.replace
// 修改原型对象中的push函数
VueRouter.prototype.push = function push (location) {
  return originalPush.call(this, location).catch(err => err)
}
// 修改原型对象中的replace函数
VueRouter.prototype.replace = function replace (location) {
  return originalReplace.call(this, location).catch(err => err)
}

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'index',
    component: () => import('../views/IndexView.vue')
  }, {
    path: '/index',
    name: 'index',
    component: () => import('../views/IndexView.vue')
  },
  // 生鲜
  {
    path: '/faseproduct',
    name: 'faseproduct',
    component: () => import('../views/faseproductView.vue')
  },
  // 食品安全
  {
    path: '/foodsafety',
    name: 'foodsafety',
    component: () => import('../views/foodsafetyView.vue')
  }, // 中央厨房

  {
    path: '/moodproduct',
    name: 'moodproduct',
    component: () => import('../views/moodproductView.vue')
  },
  // 公司产品
  {
    path: '/companyproduct',
    name: 'companyproduct',
    component: () => import('../views/companyproductView.vue')
  },

  {
    path: '/industryproduct',
    name: 'industryproduct',
    component: () => import('../views/industryproductView.vue')// 行业动态
  },

  {
    path: '/aboutus',
    name: 'aboutus',
    component: () => import('../views/aboutusView.vue')// 关于我们
  },

  {
    path: '/fasechain',
    name: 'fasechain',
    component: () => import('../views/fasechainView.vue') // 智慧冷链
  },
  // 新闻详情
  {
    path: '/Newsdetail',
    name: 'Newsdetail',
    component: () => import('../views/NewsdetailView.vue'),
    props: route => ({ param: route.query.param })
  }

]

const router = new VueRouter({
  routes
})

export default router
