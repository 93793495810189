import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Vant, { Lazyload } from 'vant'
import 'swiper/swiper.min.css'

import 'vant/lib/index.css'
import VueDirectiveImagePreviewer from 'vue-directive-image-previewer'

import 'vue-directive-image-previewer/dist/assets/style.css'

import './store/https'
Vue.config.productionTip = false
Vue.use(Lazyload)

// 注册时可以配置额外的选项
Vue.use(Lazyload, {
  lazyComponent: true
})
Vue.use(VueDirectiveImagePreviewer)
Vue.use(Vant)
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
