<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  data () {
    return {
    }
  },
  created () {
    console.log('11111')
    window.localStorage.setItem('tabIndex', 1)
  },
  mounted () {
  },
  beforeDestroy () {
    window.localStorage.clear()
  },
  destroyed () {
    window.localStorage.clear()
  },
  methods: {
    showPopup () {
      this.show = true
    }
  }
}
</script>
<style lang="less">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
