import Vue from 'vue'
import axios from 'axios'
axios.defaults.baseURL = 'https://api.wz.xinpu8.com/'
// 请求拦截器--所有通过axios发送的请求，全部都先处理：在请求头中添加
axios.interceptors.request.use(req => {
  req.headers.Authorization = sessionStorage.getItem('token')
  return req
})
// 响应了拦截器（在响应之后对数据进行一些处理）
axios.interceptors.response.use(res => res.data)
Vue.prototype.$http = axios
